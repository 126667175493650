<template>
  <section class="add-amenities-slider">
    <div class="row flex-grow">

      <Loader :loading="showLoader" />
      <div class="col-12 grid-margin">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title"> Edit Banner</h4>
            <form class="forms-sample" @submit.prevent="editSlider">
              <div class="row">
                <div class="col-md-12">
                  <div role="group" class="form-group">
                    <label class="d-block">Title</label>
                    <div>
                      <input type="text" placeholder="Title" v-model="slider.title" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div role="group" class="form-group">
                    <label class="d-block">Slider Image</label>
                    <vue-dropzone ref="myVueDropzone" id="thumb " :useCustomSlot="true" :options="thumb"
                      @vdropzone-success="bannerBigUpload">
                      <div class="dropzone-custom-content">
                        <div class="image-size-dropzone">
                          <div>
                            <img :src="`${imageURL}/${slider.banner}`" class="show-img img-fluid" />
                          </div>
                        </div>
                        <div class="subtitle p-2">
                          <span>Change</span>
                          <span style="color: #eb2027"> Image</span>
                        </div>
                      </div>
                    </vue-dropzone>
                  </div>
                </div>
              </div>
              <button type="submit" class="btn mr-2 btn-primary">Submit</button>
            </form>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css'; // Import dropzone styles
import Loader from "../components/Loader";
import { sliderApi } from "../api";
const imageURL = process.env.VUE_APP_BASE_URL;
export default {
  name: 'edit-amenities-slider',
  components: {
    vueDropzone: vue2Dropzone, Loader,
  },
  data() {
    return {
      showLoader: false,
      imageURL,
      slider: {
        title: "",
        banner: "",
        type: "amenities"
      },
      thumb: {
        url: `${imageURL}/upload`,
        maxFilesize: 1,
      },

    };
  },
  mounted() {
    this.SliderById();
  },
  methods: {
    async SliderById() {
      this.showLoader = true;
      const { data } = await sliderApi.getSliderById(this.$route.params.id);
      this.slider = data.data.data;
      this.showLoader = false;
    },

    bannerBigUpload(file, response) {
      this.slider.banner = response.data
      if (response.status === 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },

    async editSlider() {
      this.showLoader = true;
      const data = await sliderApi.updateSlider(this.$route.params.id, {
        ...this.slider,
      });
      this.SliderById();
      this.showLoader = false;
      if (data.status == 200) {
        
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.message,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.message,
        });
      }
      this.$router.push("/amenities-banner");
    },
  }
}
</script>